<template>
  <b-container class="text-center">
    <h1 class="mb-4">loading</h1>
    <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
  </b-container>
</template>

<script>
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import Config from '../config/config'

export default {
  name: 'SignOutCognitoAuth',
  created () {
    this.signOut()
  },
  methods: {
    signOut: function () {
      console.log('LoginCognitoAuth: initCognitoSDK')
      const authData = {
        ClientId: this.$config.AWS_CLIENT_ID,
        AppWebDomain: this.$config.AWS_APP_WEB_DOMAIN,
        TokenScopesArray: this.$config.AWS_TOKENSCOPESARRAY,
        RedirectUriSignIn: this.$config.AWS_REDIRECT_URI_SIGN_IN,
        RedirectUriSignOut: this.$config.AWS_REDIRECT_URI_SIGN_OUT,
        // IdentityProvider : 'Cognito User Pool',
        UserPoolId: this.$config.AWS_USER_POOL_ID,
        AdvancedSecurityDataCollectionFlag: false
      }
      const auth = new CognitoAuth(authData)
      window.localStorage.setItem('userState', '')
      window.localStorage.setItem('accessToken', '')
      window.localStorage.setItem('idToken', '')
      window.localStorage.setItem('refreshToken', '')
      window.localStorage.setItem('userGroup', '')
      window.localStorage.setItem('userSub', '')
      window.localStorage.setItem('userDate', '')
      window.localStorage.setItem('username', '')
      auth.signOut()
      window.location.assign(Config.BASE_URL)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  padding-top: 130px;
}
</style>
