<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        <div v-if="!loading">
          <div>
            <div>
              Logs count: {{ logs.length }}
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search" /><b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
              </b-input-group>
            </div>
            <b-table style="font-size: 12px" id="table" striped :items="logs" :fields="fields" :filter="filter"></b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import _ from 'lodash'

export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      fields: [
        { key: 'id', sortable: true },
        { key: 'user', sortable: true },
        { key: 'user_type', sortable: true },
        { key: 'page', sortable: true },
        { key: 'action', sortable: true },
        { key: 'model', sortable: true },
        { key: 'model_id', sortable: true },
        { key: 'created_at', sortable: true }
      ],
      filter: '',
      loading: true,
      logs: []
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      try {
        let response = await this.$Amplify.API.get('cosmos', '/stats/logs/1000/0')
        this.logs = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style></style>
