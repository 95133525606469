<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.stats">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.stats">
      <b-col>
        <div>
          <b-card no-body>
            <b-tabs pills card vertical>
              <b-tab title="Start" active><b-card-text><h1>Stats panel</h1></b-card-text></b-tab>
              <b-tab title="Quick stats" lazy><b-card-text><quick /></b-card-text></b-tab>
              <b-tab title="Cumulative" lazy><b-card-text><cumulative /></b-card-text></b-tab>
              <b-tab title="Timeseries" lazy><b-card-text><timeseries /></b-card-text></b-tab>
              <b-tab title="Timeseries detailed" lazy><b-card-text><timeseries-detailed /></b-card-text></b-tab>
              <b-tab title="Top Items" lazy><b-card-text><top-items/></b-card-text></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'

import Cumulative from '@/components/stats/Cumulative.vue'
import Quick from '@/components/stats/Quick.vue'
import Timeseries from '@/components/stats/Timeseries.vue'
import TimeseriesDetailed from '@/components/stats/TimeseriesDetailed.vue'
import TopItems from '@/components/stats/TopItems.vue'

export default {
  components: {
    Cumulative,
    Quick,
    Timeseries,
    TimeseriesDetailed,
    TopItems
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Stats"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'stats', action: 'open stats' })
    this.permissions.stats = ac.can(this.user.acgroups).readAny('stats').granted
    if (!this.permissions.stats) {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      permissions: {}
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
