<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form inline class="mb-4">
          <label class="mr-2" for="dateInput">from</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
          <label class="mr-2" for="dateInput">to</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
          <b-button class="mr-2" variant="primary" v-on:click="load">Update Date</b-button>
          <b-form-select class="mr-2" v-model="userType" :options="userTypeOptions" @change="load"/>
          <label class="mr-2" for="dateInput">No. of Results</label>
          <b-form-select class="mr-2" v-model="numResults" :options="numResultsOptions" @change="load" />
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-card>
          <h4>Top {{this.numResults}} Publications </h4>
          <b-table style="font-size: 12px" id="table" striped :items="publicationResults" :fields="fields">
            <template v-slot:cell(name)="{ item }">
              <router-link :to="{ name: 'NewsItem', params: { id: item.id }}" >{{item.name}}</router-link>
            </template>
            </b-table>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4>Top {{this.numResults}} Articles </h4>
          <b-table style="font-size: 12px" id="table" striped :items="articleResults" :fields="fieldsArticle">
            <template v-slot:cell(title)="{ item }">
              <router-link :to="{ name: 'Article', params: { id: item.id }}" >{{item.title}}</router-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <h4>Top {{this.numResults}} Topics </h4>
          <b-table style="font-size: 12px" id="table" striped :items="topicResults" :fields="fields">
            <template v-slot:cell(name)="{ item }">
              <router-link :to="{ name: 'Topic', params: { id: item.id }}" >{{item.name}}</router-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4>Top {{this.numResults}} Tags </h4>
          <b-table style="font-size: 12px" id="table" striped :items="tagResults" :fields="fields">
            <template v-slot:cell(name)="{ item }">
              <router-link :to="{ name: 'NewsTag', params: { id: item.id}}" >{{item.name}}</router-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'

export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      articleResults: [],
      tagResults: [],
      topicResults: [],
      publicationResults: [],
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      fieldsArticle: [
        { key: 'title', sortable: false },
        { key: 'count', sortable: false }
      ],
      fields: [
        { key: 'name', sortable: false },
        { key: 'count', sortable: false }
      ],
      numResults: '5',
      numResultsOptions: [
        { value: '5', text: '5' },
        { value: '10', text: '10' },
        { value: '25', text: '25' }
      ],
      userType: 0,
      userTypeOptions: [
        { value: 0, text: 'internal users' },
        { value: 1, text: 'external users' }
      ]
    }
  },
  methods: {
    load: async function () {
      const apiName = 'cosmos'
      const topicIdsPath = `/stats/topitems/topics/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
      this.topicResults = await this.$Amplify.API.get(apiName, topicIdsPath)
      const publicationIdsPath = `/stats/topitems/publications/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
      this.publicationResults = await this.$Amplify.API.get(apiName, publicationIdsPath)
      const articleIdsPath = `/stats/topitems/articles/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
      this.articleResults = await this.$Amplify.API.get(apiName, articleIdsPath)
      const tagIdsPath = `/stats/topitems/tags/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
      this.tagResults = await this.$Amplify.API.get(apiName, tagIdsPath)
      console.log(this.articleResults)
    }
  }
}
</script>

<style></style>
