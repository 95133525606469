<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form inline class="mb-4">
          <label class="mr-2" for="dateInput">from</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
          <label class="mr-2" for="dateInput">to</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
          <b-form-select class="mr-2" v-model="userType" :options="userTypeOptions" />
          <b-form-select class="mr-2" v-model="field" :options="fieldOptions" />
          <b-button variant="primary" v-on:click="reload">Apply</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="chart" :style="`width: 900px; height:  ${height};`"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  let result = Math.max(data.length * 25, 200)
  result = result + 'px'
  return result
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      endDate: moment().format('YYYY-MM-DD'),
      height: '',
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      field: 'page',
      fieldOptions: [
        { value: 'page', text: 'pages' },
        { value: 'user', text: 'user' },
        { value: 'action', text: 'actions' },
        { value: 'model', text: 'models' }
      ],
      userType: 0,
      userTypeOptions: [
        { value: 0, text: 'internal users' },
        { value: 1, text: 'external users' }
      ]
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        const apiName = 'cosmos'
        const path = `/stats/cumulative/${this.field}/${this.userType}/${this.startDate}/${this.endDate}`
        const internal = await this.$Amplify.API.get(apiName, path)
        console.log(internal)
        this.height = getHeight(internal)
        const optionTrend = {
          dataset: {
            source: internal
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' }
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          grid: {
            left: '200px'
          },
          xAxis: {
            type: 'value',
            show: true,
            splitLine: { lineStyle: { type: 'dashed' } }
          },
          yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
          },
          series: [
            {
              name: 'total',
              type: 'bar',
              label: {
                show: true,
                position: 'left',
                formatter: '{b}'
              },
              encode: {
                x: 'count',
                y: 'user'
              }
            }
          ]
        }
        await delay(10)
        echarts.init(document.getElementById('chart'), 'kpmg').setOption(optionTrend)
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    reload: async function () {
      echarts.dispose(document.getElementById('chart'))
      await delay(100)
      this.load()
    }
  }
}
</script>

<style></style>
